import MarkdownIt from 'markdown-it'

export function formatNumber (value) {
  return Number(value).toLocaleString()
}

export function chooseRandomElements (arr, num) {
  const shuffled = [...arr].sort(() => 0.5 - Math.random())
  return shuffled.slice(0, num)
}

export function sleep (milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export function isMarkdown (text) {
  // Check if the text contains any Markdown syntax
  const markdownRegex = /[#*_~`>-]|[|:-]+/
  return markdownRegex.test(text)
}
export function markdownText (text) {
  // Convert the Markdown text to HTML using markdown-it library
  const md = new MarkdownIt()

  return md.render(text)
}
